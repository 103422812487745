
.Facebook,.Youtube, .Twitter{
    font-size: 25px !important;
    margin: 10px;
    color: #fff;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    
}
.Facebook{
    background-color: #4267B2;
    border: 2px solid #4267B2; 
}
.Youtube{
    background-color: red;
    border: 2px solid red; 
}
.Twitter{
    background-color: #00acee;
    border: 2px solid #00acee; 
}

