.MuiPaper-root{
    background-color: #fff !important;
    padding: 20px;
}
.MuiPaper-root button{
    margin: 10px;
}
a{
    text-decoration: none !important;
}

.titleLeague{
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 0px;
    font-family: 'Times New Roman', Times, serif;
    font-style: italic;
    font-weight: 600;
}
.sportsType{
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 5px;
    font-family: 'Times New Roman', Times, serif;
}
.badge{
    height: 150px;
    margin: 20px auto;
}