.HomeHeader{
    width: 100%;
    height: 45vh;
    background: url("../../Images/HeaderHome.jpeg");
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: top center ;
    background-size: cover;
    border-bottom: 5px solid tomato;
}

.color-div{
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100vh;
    overflow: hidden;
}
.HomeHeader h3{
    display: block;
    margin:20vh 25%;
    font-size: 30px;
    color: #fff;
}

.homeIcon{
    color: black;
    float: left;
    margin: 50px;
    background-color: white;
    border: 5px solid white;
    border-radius: 50%;
}

@media only screen and (max-width: 539px) {
    .HomeHeader h3{
        font-size: 25px;
    }
  }

  @media only screen and (max-width: 470px) {
    .HomeHeader h3{
        font-size: 20px;
        margin:20vh 10%;
    }

    .homeIcon{
        margin: 25px;
    }
  }