 .LeagueBanner{
    width: 100%;
    height: 45vh;
}
.BannerParent{
    position: relative;
} 

.Badge img{
    height: 200px;
    width: 200px !important;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
@media only screen and (max-width: 539px) {
    .Badge img{
        height: 180px;
        width: 180px !important;
    }
}

@media only screen and (max-width: 470px) {
    .Badge img{
        height: 150px;
        width: 150px !important;
    }
}